import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import propTypes from "prop-types"

class PageTemplate extends Component {
  render() {
    const id = this.props.pageContext.id
    const language = this.props.pageContext.language
    const currentPage = this.props.data.wordpressPage
    return (
      <Layout language={language}>
        <div className="main-content">
          <h1> BÍLS </h1>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        </div>
      </Layout>
    )
  }
}

PageTemplate.propTypes = {
  data: propTypes.object.isRequired,

  edges: propTypes.array,
}

export default PageTemplate

export const pageQuery = graphql`
  query OrganizationQuery($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      content
    }
  }
`
